@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.slick-prev:before,
.slick-next:before {
    color: #333;
    display: flex;
    align-items: center;
}

.slick-slide {
    display: flex;
    justify-content: center;
    padding-left: 1.8rem
}

.blogContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 70%;
    margin: 3rem auto;
    padding: 0 1rem;
}

.blogContainerHeading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
}

.blogHeading {
    color: #333;
    font-size: 2.5rem;
    font-weight: lighter;
    text-align: center;
    margin: 0;
}

.blogList {
    list-style: none;
    padding: 0;
    width: 100%;
}

.blogItem {
    margin-bottom: 2rem;
    padding: 1.5rem;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.blogTitle {
    color: #333;
    font-size: 1.6rem;
    font-weight: lighter;
    margin: 0 0 0.5rem;
    font-family: 'Rubik', sans-serif;
}

.blogTime {
    color: #777;
    font-size: 1rem;
    margin: 0 0 1rem;

}

.blogTimeContainer {
    display: flex;
    max-width: 20%;
    justify-content: space-between;
}

.blogDescription {
    color: #555;
    font-size: 1rem;
    line-height: 1.6;
}

.blogChallenges {
    color: #555;
    font-size: 1rem;
    line-height: 1.6;
}

.blogChallengeTitle {
    color: #333;
    font-size: 1.3rem;
    font-weight: lighter;
    margin: 0 0 0.5rem;
    font-family: 'Rubik', sans-serif;
    margin-top: 20px;
}

.blogImages {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.5rem;
    padding-bottom: 0;
}

.smallBlogImage {
    width: 14rem;
    height: 7rem;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

@media (max-width: 912px) {
    .blogContainer {
        max-width: 95%;
    }

    .blogImages {
        flex-wrap: wrap;
        justify-content: center;
    }

    .smallBlogImage {
        margin-top: 1rem;
    }

    .blogTimeContainer {
        flex-wrap: wrap;
    }


}