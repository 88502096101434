.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    background-color: whitesmoke;
    color: #fff;
    box-sizing: border-box;
    opacity: 95%;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
}

.mainHeading {
    text-decoration: none;
    font-size: 1.5rem;
    color: black;
}

.mainHeading:hover {
    text-decoration: underline;
}

.right {
    display: flex;
    align-items: center;
}

.left {
    padding-left: 0.1%;
}

.routes {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.routesLink {
    margin: 0 10px;
}

.link {
    color: black;
    text-decoration: none;
    font-size: 1.2rem;
    position: relative;
    transition: all 0.3s ease;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}



.link:hover {
    text-decoration: underline;
}