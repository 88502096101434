.topAboutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.mainContainer {
    padding: 20px;
    margin: 20px;
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 70%;
    margin-right: auto;
    margin-left: auto;
}

.mainHeadingAbout {
    font-size: 6rem;
    color: black;
}

.firstPara {
    color: gray;
    font-size: 1.2rem;
    opacity: 70%;
    margin-top: 1.3rem;
    line-height: 1.5;
}

.descriptionAbout {
    width: auto;
    margin-top: 2rem;
}

.secondPara {
    color: gray;
    opacity: 90%;
    line-height: 1.7;
}

.leftAbout {
    margin: 20px;
    padding: 20px;
}

.profilePhoto {
    max-width: 100%;
    padding: 0.5rem;
    height: auto;
    border-radius: 40%;
    border-top-left-radius: 30%;
    border-bottom-right-radius: 10%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 1.5);
}

.imagesContainer {
    width: 100%;

}

.imageList {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.image {
    margin: 0 10px
}

.smallImage {
    width: 13rem;
    height: 7rem;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}


@media (max-width: 912px) {

    .imageList {
        flex-wrap: wrap;
    }

    .image {
        margin-top: 10px;
    }
}


@media (max-width: 500px) {

    .mainContainer {
        flex-direction: column;
        max-width: 95%;
        margin: auto;
        padding: 15px;
    }

    .mainHeadingAbout {
        font-size: 5rem;
    }

    .profilePhoto {
        height: 8rem;
        width: 7rem;
        padding: 0.2rem;
        margin-left: 10rem;
        margin-top: -5rem;
        border-top-left-radius: 30%;
        border-bottom-right-radius: 10%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 1.5);
    }

    .firstPara,
    .secondPara {
        font-size: 0.9rem;
    }

    .smallImage {
        width: 8rem;
        height: 4.5rem;
    }

    .descriptionAbout {
        margin-top: 1rem;
    }

    .firstPara {
        margin-top: 1rem;
    }

    .imagesContainer {
        max-width: 80%;
    }

    .imageList {
        justify-content: space-around;
    }
}