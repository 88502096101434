@import url('https://fonts.googleapis.com/css2?family=Cutive&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Yanone+Kaffeesatz:wght@200;300;400;500;600;700&display=swap');


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Cutive', serif;
  /* font-family: 'Nunito', sans-serif; */
  font-family: 'Rubik', sans-serif;
  font-family: 'Yanone Kaffeesatz', sans-serif;

}

body {
  background: whitesmoke
}


h1,
h2,
h3,
h4,
p {
  margin: 8px 0;
}

p {
  font-family: 'Rubik', sans-serif;
}

h1,
h4,
p,
a {
  color: white;
  text-transform: none;
}

ul {
  list-style-type: none;
}